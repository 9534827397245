.LocationList {
    font-size: 36pt;
    color: #808080;
    background-color: #D4EFEB;
    border: 3px solid #FBBFBF;
    border-radius: 15px;
}
.LocationList:focus {
    outline: none;
}
.LocationList option {
    font-size: 36pt;
}